.intro {
    text-align: center;
}

.form {
    width: 100%;
    max-width: 600px;
    padding: 15px;
    margin: 0 auto;
}
